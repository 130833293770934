*,
*::after,
*::before {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');
:root {
    --color-basic: #FFFFFF;
    --color-beige: #F2F0E1;
    --color-secondary: #a9c3a7;
    --color-dark-green: #273E34;
    --medium-green: #607F5C;
	--color-gray: #BFBFBF;
    --font-family: 'Montserrat', sans-serif;;
    --font-size-base: ;
}



@import url('https://cdn.syncfusion.com/ej2/materia1.css');

@import '../node_modules/@syncfusion/ej2-base/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-notifications/styles/material.css';
@import "../node_modules/@syncfusion/ej2-react-grids/styles/material.css";


.e-grid .e-gridheader tr:first-child th {
    border-top: 0 none;
    background-color: var(--medium-green);
    color: white;
}
.e-grid .e-headercelldiv {
	font-family: var(--font-family);
	font-size: 14px;
	font-weight: 600;
	font-style: normal;

}
.e-grid {
    border-color: transparent;
}
.e-grid .e-rowcell:not(.e-editedbatchcell):not(.e-updatedtd), .e-grid .e-detailrowcollapse:not(.e-editedbatchcell):not(.e-updatedtd) {
    color: var(--color-dark-green);
    border-bottom: 1px solid var(--color-secondary);
    height: 43px;
	font-family: var(--font-family);
	font-weight: 500;
	font-size: 12px
}

/* .e-grid.e-responsive .e-rowcell, .e-grid.e-responsive .e-headercelldiv {
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 152px;
} */

html {
	/* height: 100%; */
	font-size: 16px;
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
	font-weight: normal;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-ms-overflow-style: scrollbar;
	-webkit-tap-highlight-color: transparent;
}
body {
	min-height: 100vh;
    position: relative;
	padding: 0;
	margin: 0;
	/* height: 100%; */
	background: #F2F0E1; 
	-webkit-text-size-adjust: 100%;
	-moz-osx-font-smoothing: grayscale !important;
	-webkit-font-smoothing: antialiased !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	color: #484747;
	font-weight: 500;
	margin: 0rem;
}
p {
	color: #37474f;
	font-weight: 500;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0rem;
}
*::-webkit-scrollbar {
	width: 0.625rem;
	height: 0.5rem;
}
*::-webkit-scrollbar-thumb {
	background: -webkit-gradient(linear, left top, left bottom, from(#607F5C), to(#273E34));
	background: linear-gradient(to bottom, #607F5C, #273E34);
	border-radius: 25px;
}
*::-webkit-scrollbar-track {
	background: linear-gradient(to right, #F2F0E1, #F2F0E1 0.0625rem, #F2F0E1 0.0625rem, #F2F0E1);
}
.primary {
	color: #273E34;
}
.bg-primary {
	background: #fff;
}
.secondary {
	color: #607F5C;
}
.error {
	color: #AF4141 !important;
}
.bg-secondary {
	background: #273E34;
}
.g-m-auto {
	margin: 0 auto !important;
}
.g-m-0 {
	margin: 0rem !important;
}
.g-m-8 {
	margin: 0.5rem !important;
}
.g-m-10 {
	margin: 0.625rem !important;
}
.g-m-15 {
	margin: 0.9375rem !important;
}
.g-p-0 {
	padding: 0rem !important;
}
.g-p-8 {
	padding: 0.5rem !important;
}
.g-p-10 {
	padding: 0.625rem !important;
}
.g-p-15 {
	padding: 0.9375rem !important;
}
.g-mt-0 {
	margin-top: 0rem !important;
}
.g-mr-0 {
	margin-right: 0rem !important;
}
.g-mb-0 {
	margin-bottom: 0rem !important;
}
.g-ml-0 {
	margin-left: 0rem !important;
}
.g-mt-8 {
	margin-top: 0.5rem !important;
}
.g-mr-8 {
	margin-right: 0.5rem !important;
}
.g-mb-8 {
	margin-bottom: 0.5rem !important;
}
.g-ml-8 {
	margin-left: 0.5rem !important;
}
.g-mt-10 {
	margin-top: 0.625rem !important;
}
.g-mr-10 {
	margin-right: 0.625rem !important;
}
.g-mb-10 {
	margin-bottom: 0.625rem !important;
}
.g-ml-10 {
	margin-left: 0.625rem !important;
}
.g-mt-15 {
	margin-top: 0.9375rem !important;
}
.g-mr-15 {
	margin-right: 0.9375rem !important;
}
.g-mb-15 {
	margin-bottom: 0.9375rem !important;
}
.g-ml-15 {
	margin-left: 0.9375rem !important;
}
.g-pt-0 {
	padding-top: 0rem !important;
}
.g-pr-0 {
	padding-right: 0rem !important;
}
.g-pb-0 {
	padding-bottom: 0rem !important;
}
.g-pl-0 {
	padding-left: 0rem !important;
}
.g-pt-8 {
	padding-top: 0.5rem !important;
}
.g-pr-8 {
	padding-right: 0.5rem !important;
}
.g-pb-8 {
	padding-bottom: 0.5rem !important;
}
.g-pl-8 {
	padding-left: 0.5rem !important;
}
.g-pt-10 {
	padding-top: 0.625rem !important;
}
.g-pr-10 {
	padding-right: 0.625rem !important;
}
.g-pb-10 {
	padding-bottom: 0.625rem !important;
}
.g-pl-10 {
	padding-left: 0.625rem !important;
}
.g-pt-15 {
	padding-top: 0.9375rem !important;
}
.g-pr-15 {
	padding-right: 0.9375rem !important;
}
.g-pb-15 {
	padding-bottom: 0.9375rem !important;
}
.g-pl-15 {
	padding-left: 0.9375rem !important;
}
.g-0 {
	padding: 0rem !important;
}
@font-face {
	font-family: 'Arual';
	src: local('Arual'), url(./fonts/Arual.ttf) format("truetype");
}
.init {
	flex-direction: column;
	display: flex;
}
.app-bar-logo {
	height: 40px;
	margin-top: 14px;
}
.app-bar-menu {
	width: 100%;
	padding: 5px;
	padding-left: 25px;
	padding-right: 40px;
}
.font-size-title {
	font-size: 40px;
}
.footer {
	width: 100%;
	background-color: #273E34;
	flex-direction: row;
	padding: 15px;
	padding-left: 50px;
	padding-right: 50px;
	display: flex;
	color: #fff;
	align-content: space-between;
	align-items: center;
}
.box-icons-footer {
	display: flexbox;
	flex-grow: 1;
}
.box-min-footer {
	display: none;
}
.home-page {
	height: inherit;
	flex-direction: column;
	display: flex;
	align-items: center;
	align-content: center;
}
.home-page-title {
	display: flex;
	height: 800px;
	width: 100%;
	flex-flow: row wrap;
	align-items: center;
	justify-content: center;
}
  .parallax {
	width: 100%;
	height: 100%;
  }
  .ratio {
	display: flex;
	position: relative;
	width: 100%;
	height: 0;
	padding-top: 60%;
  }
  .inner {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: url('./images/jeremy-bishop-tree-home.jpeg');
	background-position: center center;
	background-size: cover;
  }
  .box {
	width: 100%;
	height: 10%;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
  }
.home-page-title-logo {
	position: absolute;
	/* background-color: #AF4141; */
	width: 50%;
}
.home-page-box {
	display: flex;
    position: relative;
    margin: 0 auto;
	width: 100%;
	background-color: #F2F0E1;
	align-items: center;
	flex-direction: column;
	padding: 70px;
	padding-bottom: 0;
}
.home-page-box-about-us {
	width: 100%;
	background-color: #273E34;
	flex-direction: row;
	display: flex;
	border-radius: 1.5em;
}
.home-page-box-about-us-content {
	width: 50%;
    padding: 50px;
    text-align: center;
    color: white;
    height: auto;
    font-size: 16px;
    line-height: 1.65em;
    font-family: 'Montserrat';
}
.home-page-box-about-us-image {
	height: inherit;
	background-image: url('./images/home-box-about-us.jpeg');
	background-position: center center;
	background-size: cover;
	flex-direction: column;
	display: flex;
	align-items: center;
	align-content: center;
	width: 50%;
	border-top-right-radius: 1.5em;
	border-bottom-right-radius: 1.5em;
}
.home-page-box-service {
	width: 100%;
	flex-direction: row;
	display: flex;
	align-content: space-between;
}
.home-page-box-service-title {
	font-size: 40px;
	font-family: 'Montserrat', sans-serif;
	color: #607F5C;
	font-weight: bold;
	margin-bottom: 70px;
}
.home-page-box-service-content-left {
	width: 35%;
    padding-bottom: 50px;
    text-align: center;
    color: white;
    height: auto;
    font-size: 16px;
    line-height: 1.65em;
    font-family: 'Montserrat', sans-serif;
    background-color: #273E34;
    margin-left: 12%;
    margin-right: 3%;
    border-radius: 1.5em;
}
.home-page-box-service-content-right {
	width: 35%;
	text-align: center;
	color: white;
	height: auto;
	font-size: 16px;
    line-height: 1.65em;
    font-family: 'Montserrat', sans-serif;
	background-color: #607F5C;
	margin-left: 3%;
	margin-right: 12%;
	border-radius: 1.5em;
	padding-bottom: 50px;
}
.home-page-box-service-content-title {
	font-size: 24px;
	font-weight: bold;
}
.home-page-box-service-image {
	width: 100%;
	margin-bottom: 30px;
	border-top-left-radius: 1.5em;
	border-top-right-radius: 1.5em;
}
.contact-us-page {
	padding: 0;
}
.box-contact-us {
	width: 100%;
	flex-direction: row;
	display: flex;
}
.box-contact-us-image {
	height: inherit;
	background: url('./images/box-contact-us.webp');
	background-position: center center;
	background-size: cover;
	width: 50%;
}
.box-contact-us-content {
	width: 50%;
	text-align: center;
	height: auto;
	font-size: 16px;
	line-height: 1.65em;
	font-family: 'Montserrat', sans-serif;
	padding-top: 100px;
	padding-bottom: 100px;
	padding-left: 12%;
	padding-right: 13%;
}
.box-contact-us-content-title {
	font-size: 40px;
	color: #273E34;
}
.services-page {
	flex-direction: column;
	display: flex;
	align-items: center;
	align-content: center;
	padding-top: 96px;
	font-size: 16px;
}
.services-page-title {
	line-height: normal;
    text-align: center;
	letter-spacing: normal;
	font-family: 'Montserrat', sans-serif;
	margin: 50px;
}
.service-page-title-spacing {
	margin-top: 35px;
}
.services-page-title-text {
	font-size: 40px; 
	font-weight: bold; 
	color: #607F5C;
}
.services-page-box {
	display: flex;
	position: relative;
    margin: 0 auto;
	width: 100%;
	align-items: center;
	flex-direction: column;
	padding-left: 70px;
	padding-right: 70px;
	margin-bottom: 40px;
}
.services-page-box-content {
	width: 100%;
	flex-direction: row;
	display: flex;
	border-radius: 1.5em;
	height: 455px;
}
.services-page-box-info-image {
	width: 50%;
	border-top-left-radius: 1.5em;
	border-bottom-left-radius: 1.5em;
    height: auto;
    object-fit: cover;
	object-position: center;
}
.services-page-box-info-text {
	width: 50%;
	padding: 10%;
	text-align: center;
	font-size: 16px;
	line-height: 1.65em;
	font-family: 'Montserrat', sans-serif;
	background-color: #A9C3A7;
	border-top-right-radius: 1.5em;
	border-bottom-right-radius: 1.5em;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.about-us-page-box-info {
	margin: 0;
	padding: 0;
	width: 60%;
}
.about-us-page-testimonials-title {
	margin: 50px;
}
.about-us-page-tesmonials {
	width: 65%;
}
.about-us-box-info {
	height: auto;
}
.about-us-box-info-text {
	height: auto;
	padding: 50px;
}
.about-us-box-image {
	object-fit: contain;
}
.about-us-page-box-testimonial-1 {
	height: 100%;
	background: url('./images/about-us-page-box-testimonial-1.webp');
	background-position: center center;
	background-size: cover;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	width: 100%;
}
.about-us-page-box-testimonial-2 {
	height: 100%;
	background: url('./images/about-us-page-box-testimonial-2.webp');
	background-position: center center;
	background-size: cover;
	flex-direction: row;
	display: flex;
	justify-content: flex-end;
	width: 100%;
}
.about-us-page-box-testimonial-3 {
	height: 100%;
	background: url('./images/about-us-page-box-testimonial-3.webp');
	background-position: center center;
	background-size: cover;
	flex-direction: row;
	display: flex;
	justify-content: flex-end;
	width: 100%;
}
.about-us-page-box-testimonial-info {
	width: 50%; 
	height: 100%;
	padding: 100px;
	text-align: center;
	font-size: 16px;
	line-height: 1.65em;
	font-family: 'Montserrat', sans-serif;
	background-color: rgba(250, 250, 250, 0.8);
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: #034D23;
	font-weight: bold;
}
.about-us-button-here-small {
	display: none;
}
.about-us-button-here-large {
	display: flex;
	width: 100%;
	justify-content: center;
}
.scroll-button {
	position: fixed; 
	left: 95%;
	bottom: 50px;
	font-size: 4.5rem;
	z-index: 1;
	cursor: pointer;
	color: #607F5C;
	background-color: #F3F3F3;
	border-radius: 50%;
}
.privacy-policy-content {
	width: 100%;
	background-color: #A9C3A7;
	border-radius: 1.5em;
	align-items: center;
	padding: 50px;
	font-size: 16px;
	line-height: 1.65em;
	font-family: 'Montserrat', sans-serif;
	text-align: justify;
}
.privacy-policy-subtitle {
	font-size: 22px;
	font-weight: bold;
}
@media (max-width: 1450px) {
	.services-page-box-info-text {
		padding: 7%;
	}
	.about-us-page-box-info {
		width: 65%;
	}
	.about-us-box-info-text {
		padding: 40px;
	}
	.about-us-page-tesmonials {
		width: 65%;
	}
	.about-us-page-box-testimonial-info {
		padding: 50px;
	}
}
@media (max-width: 1150px) {
	.app-bar-menu {
		padding-left: 15px;
		padding-right: 30px;
	}
	.app-bar-logo {
		height: 36px;
		margin-top: 14px;
	}
	.services-page {
		font-size: 15px;
	}
	.services-page-title-text {
		font-size: 37px;
	}
	.services-page-box-info-text {
		padding: 5%;
		font-size: 15px;
	}
	.about-us-page-box-info {
		width: 75%;
	}
	.about-us-box-info-text {
		padding: 30px;
	}
	.about-us-page-tesmonials {
		width: 75%;
	}
	.about-us-page-box-testimonial-info {
		padding: 50px;
		font-size: 15px;
	}
	  .ratio {
		height: 100%;
		padding-top: 100%;
	  }
	  .box {
		height: 10%;
		justify-content: flex-end;
	  }
}
@media (max-width: 900px) {
	.app-bar-menu {
		padding-left: 5px;
		padding-right: 20px;
	}
	.app-bar-logo {
		height: 32px;
		margin-top: 8px;
	}
	.services-page {
		font-size: 14px;
	}
	.services-page-title-text {
		font-size: 35px;
	}
	.services-page-box-content {
		height: auto;
	}
	.services-page-box-info-text {
		padding: 30px;
		font-size: 14px;
	}
	.about-us-page-box-info {
		width: 85%;
	}
	.about-us-page-tesmonials {
		width: 85%;
	}
	.about-us-page-box-testimonial-info {
		padding: 30px;
		font-size: 14px;
	}
	  .box {
		height: 20%;
		justify-content: flex-end;
	  }
}
@media (max-width: 650px) {
	.app-bar-menu {
		padding-left: 0;
		padding-right: 10px;
	}
	.app-bar-logo {
		height: 24px;
		margin-top: 12px;
	}
	.home-page-title-logo {
		width: 60%;
	}
	.font-size-title {
		font-size: 30px;
	}
	.home-page-box {
		padding: 25px;
		padding-top: 30px;
		padding-bottom: 0;
	}
	.home-page-box-about-us {
		flex-direction: column;
		align-content: space-between;
	}
	.home-page-box-about-us-content {
		width: 100%;
		padding: 30px;
		font-size: 13px;
	}
	.home-page-box-about-us-image {
		display: none;
	}
	.home-page-box-service-title {
		font-size: 30px;
		margin-bottom: 30px;
	}
	.home-page-box-service {
		flex-direction: column;
		align-content: space-around;
	}
	.home-page-box-service-image {
		margin-bottom: 17px;
	}
	.home-page-box-service-content-left {
		width: 100%;
		font-size: 13px;
		margin: 0;
		background-color: #607F5C;
		padding-bottom: 30px;
	}
	.home-page-box-service-content-right {
		width: 100%;
		font-size: 13px;
		margin: 0;
		padding-bottom: 30px;
		margin-top: 30px;
	}
	.home-page-box-service-content-title {
		font-size: 20px;
	}
	.contact-us-page {
		padding: 0;
	}
	.box-contact-us-image {
		display: none;
	}
	.box-contact-us-content {
		width: 100%;
		font-size: 13px;
		padding: 50px;
		padding-top: 30px;
		padding-bottom: 30px;
	}
	.box-contact-us-content-title {
		font-size: 30px;
	}
	.services-page {
		font-size: 13px;
	}
	.services-page-title {
		margin: 40px;
	}
	.service-page-title-spacing {
		margin-top: 25px;
	}
	.services-page-title-text {
		font-size: 30px;
	}
	.services-page-box {
		padding-left: 25px;
		padding-right: 25px;
		margin-bottom: 30px;
	}
	.services-page-box-content {
		flex-direction: column;
		height: auto;
		border-bottom-left-radius: 1.5em;
		border-bottom-right-radius: 1.5em;
	}
	.services-page-box-info-image {
		width: 100%;
		border-top-left-radius: 1.5em;
		border-top-right-radius: 1.5em;
		border-bottom-left-radius: 0;
		object-fit: contain;
	}
	.services-page-box-info-text {
		width: 100%;
		font-size: 13px;
		border-top-right-radius: 0;
		border-bottom-left-radius: 1.5em;
	}
	.about-us-page-box-info {
		padding-left: 20px;
		padding-right: 20px;
		margin-bottom: 0;
	}
	.about-us-page-tesmonials {
		width: 100%;
	}
	.about-us-page-box-testimonial-info {
		height: 100%;
		padding: 16px;
		font-size: 12px;
	}
	.about-us-button-here-small {
		display: flex;
		width: 100%;
		justify-content: center;
	}
	.about-us-button-here-large {
		display: none;
	}
	.scroll-button {
		left: 85%;
		bottom: 40px;
		font-size: 3rem;
	}
	.footer {
		padding: 0;
		padding-left: 10px;
		padding-right: 10px;
	}
	.display-footer {
		display: none;
	}
	.box-min-footer {
		display: flex;
		flex-direction: column;
		font-size: 11px;
		margin-left: 10px;
	}
	.privacy-policy-content {
		padding: 30px;
		font-size: 13px;
	}
	.privacy-policy-subtitle {
		font-size: 18px;
	}
}


/** ============= loader ============= **/
.loading {
    align-items: center;
    background-color: var(--color-basic);
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
}
/** ============= loader end ============= **/



/** ============= header ============= **/
.header {
    align-items: center;
    background-color: var(--color-secondary);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    height: 100px;
    justify-content: center;
    width: 100%;
}

.app-logo {
	height: 34px;
}
@media screen and (min-width: 576px) { 
	.header {
		padding-left: 48px;
		justify-content: flex-start;
	}
	.app-logo {
		height: 40px;
	}
}	
/** ============= header End ============= **/

  
/** ============= FormLogin FormSignup ============= **/
.container {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	padding: 103px 0;
}

.form-login {
    background-color: var(--color-basic);
    min-height: 660px;
    max-width: 480px;
    padding: 50px 20px 30px;
    width: 95%;
    border-radius: 16px;
    border-bottom: 2px solid var(--color-dark-green);
    
}
@media screen and (min-width: 576px) { 
    .form-login{
        border-radius: 36px; 
        padding: 50px 40px 20px;
		border-bottom: none;
    }
}
.form-signup {
	background-color: var(--color-basic);
    min-height: 660px;
    max-width: 480px;
    padding: 50px 20px 30px;
    width: 95%;
    border-radius: 16px;
    border-bottom: 2px solid var(--color-dark-green);
}
@media screen and (min-width: 576px) { 
	.form-signup {
        border-radius: 36px; 
		border-bottom: none;
		padding: 50px 40px 20px;

    }
}


.form-title {
    color: var(--color-dark-green);
    font-family: var(--font-family);
    font-size: 40px;
    font-weight: 400;
    letter-spacing: 0em;
    line-height: 49px;
    text-align: center;

}
.form-container-formsignup {
	margin-top: 70px;
    display: grid;
    gap: 40px;
}
.form-container-login {
	margin-top: 130px;
    display: grid;
    gap: 40px;
}
.input-group {
    position: relative;
    color: var(--color-dark-green);
}
input {
    width: 100%;
    background: none;
    color: var(--color-dark-green);
    font-size: 16px;
    padding: 10px 10px 10px 0;
    border: none;
    outline: none;
    border-bottom: 2px solid var(--medium-green);
    font-family: var(--font-family);
    
}
label {
	color: var(--color-dark-green);
	font-family: var(--font-family);
	font-weight: 400;
    position: absolute;
    left: 0;
    top: 13px;
    transition: 0.5s ease all;
    pointer-events: none;
    -webkit-transition: 0.5s ease all;
    -moz-transition: 0.5s ease all;
    -ms-transition: 0.5s ease all;
    -o-transition: 0.5s ease all;
}
input:focus~label,
input:valid~label {
    top: -14px;
    left: 0;
    font-size: 12px;
    font-weight: 400;
    font-family: var(--font-family);

}
.border {
    position: relative;
    display: block;
    width: 100%;
}
.border::before{
    content: "";
    height: 2px;
    width: 0%;
    bottom: 0;
    position: absolute;
	background: var(--color-secondary);
	background: linear-gradient(87deg, var(--color-secondary) 7%, var(--color-dark-green) 80%);
    transition: 0.3s ease width;
    left: 0;
    -webkit-transition: 0.3s ease width;
    -moz-transition: 0.3s ease width;
    -ms-transition: 0.3s ease width;
    -o-transition: 0.3s ease width;
}
input:focus~.border::before{
    width: 100%;
}
.button-login {
	border-radius: 4px;
	cursor: pointer;
	padding: 8px 16px 8px 16px;
	margin: 80px auto 0;
	border: none;
	background-color: var(--color-dark-green);
	color: var(--color-basic);
	font-size: 16px;
	font-weight: 400;
	font-family: var(--font-family);
	transition: background-color 0.2s ease;
}
.button-login:hover {
	background-color: var(--medium-green);
}
.button-login:active {
	transform: scale(0.95);
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), inset 0px 2px 2px rgba(0, 0, 0, 0.5);
	transition: all 0.3s ease;
}


.button-formsignup {
    border-radius: 4px;
	cursor: pointer;
    padding: 8px 16px 8px 16px;
    margin: 27px auto 0;
    border: none;
    background-color: var(--color-dark-green);
    color: var(--color-basic);
    font-size: 16px;
    font-weight: 400;
    font-family: var(--font-family);
	transition: background-color 0.2s ease;
}
.button-formsignup:active {
	transform: scale(0.95);
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), inset 0px 2px 2px rgba(0, 0, 0, 0.5);
	transition: all 0.3s ease;
}

.button-formsignup:hover {
	background-color: var(--medium-green);
} 
.paragraph-login {
	color: var(--color-dark-green);
	font-family: var(--font-family);
	font-weight: 400;
	text-align: center;
	margin: 103px 0 0 0;
	display: grid;
}
@media screen and (min-width: 380px) { 
	.paragraph-login {
		display: block;
	}
}

.paragraph-formsignup {
	color: var(--color-dark-green);
	font-family: var(--font-family);
	font-weight: 400;
	text-align: center;
	margin: 55px 0 0 0;
}
.paragraph-login span,
.paragraph-formsignup span {
	color: var(--color-secondary);
	text-decoration: underline;
	cursor: pointer;
}


.see-password {
    border: none;
    background: transparent;
    position: absolute;
    top: 50%;
    color: lightgrey;
    right: -5px;
    transform: translateY(-50%);
    cursor: pointer;
}
.link {
	color: var(--color-secondary);
	margin-left: 5px;
} 
  
/** ============= FormLogin FormSignup End ============= **/



/** ============= ClientDashboard ============= **/
.dashboard-logout-container {
    justify-content: right;
    display: flex;
    max-width: 1223px;
    width: 95%;
    top: -8px;
    margin: 41px auto 0;
}
.dashboard-logout {
	background: none;
	border: none;
	cursor: pointer;
	color: var(--medium-green);
	text-decoration: underline;
	font-family: var(--font-family);
	font-size: 20px;
	font-weight: 600;
	line-height: 24px;
	align-self: flex-start;
	transition: color 0.2s ease;
}
.dashboard-logout:hover {
	color: var(--color-secondary);
}
.dashboard-message {
	display: flex;
	justify-content: left;
	max-width: 1223px;
	width: 95%;
	margin: 49px auto 30px;
	/* align-items: flex-start; */
}
.container-greeting {
	display: flex;
	flex-direction: column;
}
  

.dashboard-greeting {
	color: var(--color-dark-green);
	font-family: var(--font-family);
	font-size: 40px;
	font-weight: 600;
	font-style: normal;
	line-height: 49px;


}
.dashboard-paragraph {
	color: var(--medium-green);
	font-family: var(--font-family);
	font-size: 20px;
	margin-top: 20px;
	font-weight: 400;
	line-height: 24px;
	font-style: normal;
}
@media screen and (min-width: 576px) { 
	.dashboard-paragraph {
		margin-top: 30px;
		
	}
}




.dashboard-container {
	display: flex;
	justify-content: center;
	/* width: 100%; */
	
}


.dashboard {
	background-color: var(--color-basic);
	max-width: 1223px;
	width: 95%;
	height: 531px;
	border-radius: 16px;
	margin-bottom: 110px;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	grid-gap: 20px;
	padding: 20px;
	grid-auto-rows: 150px;
	overflow-y: scroll;
}
@media screen and (min-width: 576px) { 
	.dashboard {
		height: auto;
		min-height: 531px;
		overflow-y: hidden;
	}
	
}

@media screen and (min-width: 768px) { 
	.dashboard {
		border-radius: 36px;
		
	}
}
@media screen and (min-width: 1100px) { 
	.dashboard {
		grid-template-columns: repeat(auto-fill, minmax(301px, 1fr));
	}
}
.dashboard-item {
	display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    width: 230px;
    margin: auto;
}
  
.dashboard-title {
	color: var(--medium-green);
	font-size: 16px;
	font-weight: 600;
	font-family: var(--font-family);
	margin-bottom: 10px;
	Line-height: 19.5px;

}
  
.dashboard-number {
	color: var(--color-dark-green);
	font-size: 40px;
	font-weight: 400;
	font-family: var(--font-family);
}
  



/** ============= ClientDashboard end ============= **/


/** ============= AdminClientEditor ============= **/
.admin-logout-container {
    justify-content: right;
    display: flex;
    max-width: 1223px;
    width: 95%;
    top: -8px;
    margin: 41px auto 0;
}


.admin-logout {
	background: none;
	border: none;
	cursor: pointer;
	color: var(--medium-green);
	text-decoration: underline;
	font-family: var(--font-family);
	font-size: 20px;
	font-weight: 600;
	line-height: 24px;
	align-self: flex-start;
	transition: color 0.2s ease;
}
.admin-logout:hover {
	color: var(--color-secondary);
}
.admin-message {
    display: flex;
    justify-content: space-between;
    max-width: 1223px;
    width: 95%;
    margin: 60px auto 67px;
    align-items: flex-start;
}
.container-greeting {
	display: flex;
	flex-direction: column;
}
  

.admin-greeting {
	color: var(--color-dark-green);
	font-family: var(--font-family);
	font-size: 40px;
	font-weight: 600;
	font-style: normal;
	line-height: 49px;
	margin: 20px;

}
.admin-input {
	border: none;
	text-transform: capitalize;
}
.admin-input::placeholder {
	/* color: var(--color-dark-green); */
	color: #BFBFBF;
	font-size: 16px;
}
.admin-paragraph {
	color: var(--color-dark-green);
	font-family: var(--font-family);
	font-size: 20px;
	font-weight: 400;
	/* line-height: 24px; */
	font-style: normal;
	margin: 0;
}
@media screen and (min-width: 576px) { 
	.admin-paragraph {
		margin-top: 30px;
		
	}
}
.line-customer {
    width: 180px;
    height: 1px;
    background-color: var(--medium-green);
}
@media screen and (min-width: 576px) { 
	.line-customer {
		width: 280px;
	}
}
@media screen and (min-width: 768px) { 
	.line-customer {
		width: 394px;
	}
}








.admin-container {
	display: flex;
	justify-content: center;
	width: 100%;
}

.admin {
	background-color: var(--color-basic);
	max-width: 1223px;
	width: 95%;
	height: 531px;
	border-radius: 16px;
	margin-bottom: 162px;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	grid-gap: 20px;
	padding: 20px;
	grid-auto-rows: 150px;
	overflow-y: scroll;
}
@media screen and (min-width: 576px) { 
	.admin {
		height: auto;
		min-height: 531px;
		overflow-y: hidden;
	}
	
}
@media screen and (min-width: 768px) { 
	.admin {
		border-radius: 36px;
		-webkit-border-radius: 36px;
		-moz-border-radius: 36px;
		-ms-border-radius: 36px;
		-o-border-radius: 36px;
	}
}

@media screen and (min-width: 1100px) { 
	.admin {
		grid-template-columns: repeat(auto-fill, minmax(301px, 1fr));
	}
}
.admin-item {
	display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
}
  
.admin-title {
	color: var(--medium-green);
	font-size: 16px;
	font-weight: 600;
	font-family: var(--font-family);
	margin-bottom: 10px;
	Line-height: 19.5px;

}
  
.admin-number {
	color: var(--color-dark-green);
	font-size: 40px;
	font-weight: 40;
	font-family: var(--font-family);
	width: 251px;
}
.admin-number::placeholder {
	/* color: var(--color-dark-green); */
	color: var(--color-gray);
}


.admin-buttons {
    display: flex;
	justify-content: center;
    gap: 30px;
    max-width: 1223px;
    width: 95%;
	margin: -90px auto 95px;
}
@media screen and (min-width: 576px) { 
	.admin-buttons {
		display: flex;
		justify-content: right;
	}
}
.admin-cancel {
	background-color: var(--color-dark-green);
	cursor: pointer;
	font-family: var(--font-family);
	font-size: 16px;
	font-weight: 400;
	background: none;
	border: none;
	text-decoration: underline;
	Line-height: 19.5px;

	transition: color 0.2s ease;
}
.admin-cancel:active {
	transform: scale(0.95);
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), inset 0px 2px 2px rgba(0, 0, 0, 0.5);
	transition: all 0.3s ease;
}
.admin-cancel:hover {
	color: var(--medium-green);
}

.admin-changes {
	background-color: var(--color-dark-green);
	cursor: pointer;
	color: var(--color-basic);
    font-family: var(--font-family);
	font-weight: 400;
    font-size: 16px;
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
	Line-height: 19.5px;
	transition: background-color 0.2s ease;
}
.admin-changes:active {
	transform: scale(0.95);
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), inset 0px 2px 2px rgba(0, 0, 0, 0.5);
	transition: all 0.3s ease;
}
.admin-changes:hover {
	background-color: var(--medium-green);
}
/** ============= AdminClientEditor end ============= **/


/** ============= AdminList ============= **/
.adminlist-logout-container {
	justify-content: right;
    display: flex;
    max-width: 1223px;
    width: 95%;
    top: -8px;
    margin: 41px auto 0;
}
.adminlist-logout {
	background: none;
	border: none;
	cursor: pointer;
	color: var(--medium-green);
	text-decoration: underline;
	font-family: var(--font-family);
	font-size: 20px;
	font-weight: 600;
	line-height: 24px;
	align-self: flex-start;
	transition: color 0.2s ease;
}
.adminlist-logout:hover {
	color: var(--color-secondary);
}
.adminlist-message  {
	display: flex;
	justify-content: space-between;
	max-width: 1223px;
	width: 95%;
	margin: 49px auto 36px;
	align-items: flex-start;
}
.container-greeting {
	display: flex;
	flex-direction: column;
}
.adminlist-greeting{
	color: var(--color-dark-green);
	font-family: var(--font-family);
	font-size: 40px;
	font-weight: 600;
	font-style: normal;
	line-height: 49px;
}

.grid-container {
	max-width: 1224px;
	margin: auto;
	width: 95%;
}
  
.grid-buttons-container {
    display: flex;
    justify-content: center;
    max-width: 1223px;
    margin: 120px auto 86px;
	
}
@media screen and (min-width: 576px) { 
	.grid-buttons-container  {
		display: flex;
		justify-content: right;
	}
}
.grid-button {
	background-color: var(--color-dark-green);
	cursor: pointer;
	color: var(--color-basic);
	font-family: var(--font-family);
	font-weight: 400;
	font-size: 16px;
	padding: 8px 16px;
	border-radius: 4px;
	border: none;
	Line-height: 19.5px;
	transition: background-color 0.2s ease;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
	transition: background-color 0.2s ease;
}
.grid-button:hover {
	background-color: var(--medium-green);
}

.grid-button:active {
	transform: scale(0.95);
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), inset 0px 2px 2px rgba(0, 0, 0, 0.5);
	transition: all 0.3s ease;
  }
/** ============= AdminList end ============= **/





  
